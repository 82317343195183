progress {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  appearance: none;
}

progress::-webkit-progress-bar {
  background-color: #f3f3f3;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #4caf50;
  border-radius: 10px 0 0 10px;
}

progress::-moz-progress-bar {
  background-color: #4caf50;
  border-radius: 10px 0 0 10px;
}

.loading-bars {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.loading-bars div {
  width: 6px;
  height: 100%;
  background-color: #00647E;
  margin: 0 2px;
  animation: loading-bars 1s infinite ease-in-out;
}

.loading-bars div:nth-child(1) {
  animation-delay: -0.4s;
}

.loading-bars div:nth-child(2) {
  animation-delay: -0.2s;
}

.loading-bars div:nth-child(3) {
  animation-delay: 0s;
}

@keyframes loading-bars {
  0%, 80%, 100% {
    transform: scaleY(0.4);
  }
  40% {
    transform: scaleY(1);
  }
}

.dots::after {
  content: '';
  animation: dots 1.5s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

.table-container {
  padding: 10px;
  margin: 20px;
}

th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: white;
}

th {
  background-color: white;
  color: #00647E;
  position: relative;
  text-align: left;
  opacity: 0.7;
  max-width: 200px;
  background: transparent;
  border-radius: none;
  box-shadow: none;
  font-size: 0.65rem;
  font-weight: 700;
  text-transform: uppercase;
}

td {
  background-color: white;
  color: #000;
  position: relative;
  text-align: left;
  opacity: 0.7;
  border-radius: none;
  box-shadow: none;
  font-size: 0.80rem;
  max-width: 200px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
}

a {
  color: #00647E;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

select {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 300px;
  padding: 10px 5px 10px 5px;
}

select:focus {
  border-color: #00647E;
  box-shadow: 0 0 5px rgba(0, 100, 126, 0.5);
  outline: none;
  width: 300px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #00647E;
}

input[type="checkbox"]:checked {
  background-color: #00647E;
  border-color: #00647E;
}

input[type="checkbox"]:focus {
  outline: 2px solid #00647E;
  outline-offset: 2px;
}