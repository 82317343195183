.tab-content {
    display: none;
    border-top: none;
    padding-top: 20px;
}

.tab-content.active {
    display: block;
}

.tab-header {
    display: flex;
    cursor: pointer;
}

.tab-header div {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1rem;
    color: #8c8c8c;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
}

.tab-header div.active {
    color: #00647E;
    border-bottom: 2px solid #00647E;
}